import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import Section from '@latitude/section';
import { Box } from '@latitude/box';
import { Heading3 } from '@latitude/heading';
import { Link } from '@latitude/link';
import { BREAKPOINT, COLOR, FONT_FAMILY } from '@latitude/core/utils/constants';
import BrandedCard from '@/components/CardMedia/BrandedCard';
import { SectionSubtitle, SectionTitle } from './_sectionHeading';

import BRANDED_CARD_DATA from './data/interest-free-cards.json';

const CARD_INFORMATION = [
  {
    title: 'Apple CreditLine',
    href: 'https://cardapp.latitudefinancial.com/apply/creditline/',
    trackId: 'interest-free--compare-cards--fom--creditline-afs',
    imageSrc: require('../../images/credit-cards/creditline-afs-107x68.webp'),
    productid: ['CCAUAFS']
  },
  {
    title: 'CreditLine',
    href: '#',
    trackId: 'interest-free--compare-cards--fom--creditline',
    imageSrc: require('../../images/credit-cards/creditline-107x68.webp'),
    productid: ['CCAUCCL']
  },
  {
    title: "Buyer's Edge",
    href: '#',
    trackId: 'interest-free--compare-cards--fom--buyers-edge',
    imageSrc: require('../../images/credit-cards/buyers-edge-107x68.webp'),
    productid: ['CCAUBE']
  }
];
const cardsHeading = 'Other Latitude credit cards with Interest Free plans';

function InterestFreeCardsSection({ id }) {
  return (
    <Section id={id} css={{ padding: '64px 0' }}>
      <SectionTitle>
        All Latitude credit cards with Interest Free payment plans
      </SectionTitle>
      <SectionSubtitle>
        Get the lowdown on the finer details before you apply.
      </SectionSubtitle>

      <br />
      <CardContainer isResponsive>
        <BrandedCard
          isBranded
          hasMininumHeight
          cardMediaBackground
          data={BRANDED_CARD_DATA.cards}
        />
      </CardContainer>

      <Box>
        <Container isResponsive>
          <Title2 className="text-center">{cardsHeading}</Title2>
          <OtherCards>
            {CARD_INFORMATION.map((item, i) => (
              <OtherCard key={i}>
                <CardImage src={item.imageSrc.default} alt={item.title} />
                <CardTitle>{ReactHtmlParser(item.title)}</CardTitle>
              </OtherCard>
            ))}
          </OtherCards>
        </Container>
      </Box>
    </Section>
  );
}

const CardContainer = styled(Box)`
  position: relative;
  margin: 20pt auto 0 auto;
`;

const Container = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 0 0 43px;
  }
`;

const Title2 = styled(Heading3)`
  color: #000;
  font-family: ${FONT_FAMILY.TITLE};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

const OtherCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30pt;
  justify-content: center;
  padding: 5pt;
`;

const OtherCard = styled.div`
  padding: 5pt;
  width: 120px;
`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 8px;
`;

const CardTitle = styled.div`
  text-align: center;
  font-family: ${FONT_FAMILY.TITLE};
  font-weight: 600;
  padding-left: 2px;
  line-height: 1.2;
`;

export default InterestFreeCardsSection;
