import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';

import Section from '@latitude/section';
import { ImportantInformation } from '@latitude/important-information';

function ImportantInformationSection() {
   /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Section>
      <ImportantInformation
        data={{
          content: {
            importantInformationSectionOne: [
              '<sup>1</sup>&nbsp;To take advantage of up to 55 days Interest Free on everyday credit card purchases, you need to pay the full closing balance (excluding un-expiring Interest Free Payment Plans) on each statement of account by the applicable due date.',
              '<sup>*</sup>&nbsp;Extended Interest Free terms may vary. Available at participating retailers to approved applicants only. T&Cs, fees, charges and exclusions apply.',
              '&reg;&nbsp;Mastercard is a registered trademark and the circles design is a trademark of Mastercard International Incorporated.',
              'Visa and the Visa brand are registered trademarks of Visa International.'
            ]
          }
        }}
        sectionOneColummClasses="offset-1"
        {...state?.importantInfoData?.[0]}
      />
    </Section>
  );
}

export default ImportantInformationSection;
